import React from "react";
import PropTypes from "prop-types";
import CoreFilter from "./../CoreFilter";

const editorialTypes = [
  // { id: "art", name: "Art" },
  // { id: "film", name: "Film" },
  // { id: "design", name: "Design" },
  // { id: "music", name: "Music" },
  // { id: "literature", name: "Literature" },
  { id: "culture", name: "Culture" },
  { id: "style", name: "Style" },
  { id: "expression", name: "Expression" },
  { id: "opinion", name: "Opinion" },
];

const EditorialFilter = ({ selected }) => {
  return <CoreFilter selected={selected} filterList={editorialTypes} modal />;
};

export default EditorialFilter;

EditorialFilter.propTypes = {
  selected: PropTypes.func,
};
